import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';

export default function NavBar() {
    const navigate = useNavigate();
    const { isConnected } = useWeb3ModalAccount();
    const { disconnect } = useDisconnect();

    return (
        <>
            <nav>
                <div className="container">
                    <div className={styles.navBar}>
                        <img src="/assets/logo.svg" alt="logo" onClick={() => navigate('/')} />
                        {isConnected && (
                            <button onClick={() => disconnect()} className={styles.disconnectButton}>Disconnect</button>
                        )}
                    </div>
                </div>
            </nav>
            <div className="mainSection">
                <Outlet />
            </div>
        </>
    )
}