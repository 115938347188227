import styles from './index.module.scss';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers5/react';


export default function Login() {
    const naviagte = useNavigate();
    const { isConnected } = useWeb3ModalAccount();
    const { open } = useWeb3Modal();

    useEffect(() => {
        if (isConnected) {
            naviagte('/home');
        } else {
            naviagte('/login');
        }
    }, [isConnected, naviagte]);

    // useEffect(() => {
    //     try {
    //         const host = document.querySelector('w3m-button');
    //         if (host !== null && host.shadowRoot !== null) {
    //             let a = host.shadowRoot.querySelector('w3m-connect-button');
    //             if (a !== null && a.shadowRoot !== null) {
    //                 let b = a.shadowRoot.querySelector('wui-connect-button');
    //                 if (b !== null && b.shadowRoot !== null) {
    //                     let c = b.shadowRoot.querySelector('button');
    //                     c.style.width = '80%';
    //                     c.style.height = '53px';
    //                     c.style.borderRadius = '12px';
    //                     c.style.margin = 'auto';
    //                     c.style.background = '#3b5aff';
    //                 }
    //             }
    //         };
    //     } catch (error) {
    //         console.error(error);
    //     }
    // })

    return (
        <>
            <div className={styles.box}>
                <h1>
                    <img src="/assets/login/rushicon.svg" alt='banner' /> Rush Airdrop for Investors
                </h1>
                <img src="/assets/login/banner.svg" alt='banner' className={styles.banner} />

                <button className={styles.connectButton} onClick={() => open()}>
                    Connect Wallet
                </button>

                {/* <w3m-button /> */}

                <div className={styles.info}>
                    <img src="/assets/login/walletIcon.svg" alt='banner' /> Connect your wallet to see the vaults
                </div>
            </div>
        </>
    )
}