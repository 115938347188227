import Config from 'Config';

const initialState = {
    isWalletConnecting: false,
    walletAddress: null,
    web3Provider: null,
    ETH_INFURA_URL: Config.ETH_INFURA_URL,
    POLYGON_INFURA_URL: Config.POLYGON_INFURA_URL,
    userDetails: {},
    STAKING_CONTRACT_ADDRESS: Config.STAKING_CONTRACT_ADDRESS,
    STAKING_CONTRACT_ABI: Config.STAKING_CONTRACT_ABI,
    ERC20_ABI: Config.ERC20_ABI,
    ERC20_ADDRESS: Config.ERC20_ADDRESS,
    USER_BULL_BALANCE: 0,
    HOME_PAGE_LOADING: true,
    chainId: null,
    NETWORKS: Config.NETWORKS
};

export default function authReducer(state = initialState, action) {

    switch (action.type) {
        case 'isWalletConnecting':
            return {
                ...state,
                isWalletConnecting: action.payload,
            };
        case 'LOAD_USER_DETAILS':
            return {
                ...state,
                userDetails: action.payload,
            };
        case 'USER_SIGN_OUT':
            return {
                ...state,
                walletAddress: null
            };
        case 'LOAD_WALLET_ADDRESS':
            return {
                ...state,
                walletAddress: action.payload
            };
        case 'LOAD_WEB3_PROVIDER':
            return {
                ...state,
                web3Provider: action.payload,
            };
        case 'HOME_PAGE_LOADING':
            return {
                ...state,
                HOME_PAGE_LOADING: action.payload,
            };
        case 'LOAD_CHAIN_CHANGED':
            return {
                ...state,
                chainId: action.payload,
            };
        case 'USER_BULL_BALANCE':
            return {
                ...state,
                USER_BULL_BALANCE: action.payload,
            };
        default:
            return state;
    }
}
