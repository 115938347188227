import axios from 'axios';
const url = axios.create({ baseURL: process.env.REACT_APP_API_PREFIX });

url.interceptors.request.use(async function (config) {
    try {

        config.headers = {

        };
    } catch (error) {

    };
    return config;
}, function (error) {
    return Promise.reject(error);
});

url.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error?.response?.data ? error.response.data : error.response);
});

export default url;