import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './redux';
import reportWebVitals from './reportWebVitals';
import Routes from 'routers';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import config from 'Config';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

const metadata = {
  name: 'Bullieverse',
  description: 'Rush Token Staking',
  url: 'https://splendorous-rolypoly-83fdbc.netlify.app/home',
  icons: ['https://app.bullieverse.com/assets/NavBar/logo.svg']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  // defaultChainId: 137,
  chains: config.chains,
  projectId: '140fad0fbfa1e7bfb7120c572bc00226',
})

export const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <Provider store={store}>
    <React.Suspense fallback={<></>}>
      <Routes />
    </React.Suspense >
  </Provider >
);
reportWebVitals();
