import Web3 from 'web3';
import { ethers } from 'ethers';

const numberOfStakes = () => async (dispatch, getState) => {
    const { STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, walletAddress, POLYGON_INFURA_URL } = getState().auth;
    let currentProvider = new Web3(POLYGON_INFURA_URL);
    let contract = new currentProvider.eth.Contract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
    let numberOfStakes = await contract.methods.numberOfStakes(walletAddress).call();
    return numberOfStakes.toString();
}

const userStakedAmount = () => async (dispatch, getState) => {
    const { STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, walletAddress, POLYGON_INFURA_URL } = getState().auth;
    let currentProvider = new Web3(POLYGON_INFURA_URL);
    let contract = new currentProvider.eth.Contract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
    let amount = await contract.methods.userStakedAmount(walletAddress).call();
    return amount.toString();
}

const pools = (poolId) => async (dispatch, getState) => {
    const { STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, POLYGON_INFURA_URL } = getState().auth;
    let currentProvider = new Web3(POLYGON_INFURA_URL);
    let contract = new currentProvider.eth.Contract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
    return await contract.methods.pools(poolId).call();
}

const getMultiplePoolDetails = () => async (dispatch, getState) => {
    const { STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, POLYGON_INFURA_URL } = getState().auth;
    let currentProvider = new Web3(POLYGON_INFURA_URL);
    let contract = new currentProvider.eth.Contract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
    return await contract.methods.getMultiplePoolDetails([0, 1, 2]).call();
}

const getMultipleStakeInfo = (numberOfStakes) => async (dispatch, getState) => {
    let createNewArray = Array.from({ length: numberOfStakes }, (v, i) => i)
    const { STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, walletAddress, POLYGON_INFURA_URL } = getState().auth;
    let currentProvider = new Web3(POLYGON_INFURA_URL);
    let contract = new currentProvider.eth.Contract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
    return await contract.methods.getMultipleStakeInfo(walletAddress, createNewArray).call();
}

const balanceOfERC20 = () => async (dispatch, getState) => {
    const { ERC20_ABI, ERC20_ADDRESS, walletAddress, POLYGON_INFURA_URL } = getState().auth;
    let polygonWeb3 = new Web3(POLYGON_INFURA_URL);
    let POLYGON_ERC20_CONTRACT = new polygonWeb3.eth.Contract(ERC20_ABI, ERC20_ADDRESS).methods;
    let balance = await POLYGON_ERC20_CONTRACT.balanceOf(walletAddress).call();
    balance = balance.toString() / Math.pow(10, 18);
    let splitBalance = balance.toString().split('.');
    dispatch({ type: 'USER_BULL_BALANCE', payload: Number(splitBalance[0]) });
    return balance;
}

const allowance = (amount) => async (dispatch, getState) => {
    const { STAKING_CONTRACT_ADDRESS, ERC20_ABI, ERC20_ADDRESS, walletAddress, web3Provider } = getState().auth;
    let filterdAmount = Number(amount) / Math.pow(10, 18);
    const signer = await web3Provider.getSigner();
    const contract = new ethers.Contract(ERC20_ADDRESS, ERC20_ABI, signer);

    let spendingLimit = await contract.allowance(walletAddress, STAKING_CONTRACT_ADDRESS);
    let filteredSpendingLimit = ethers.utils.formatUnits(spendingLimit, 18);


    if (Number(filteredSpendingLimit) < Number(filterdAmount)) {
        let { hash } = await contract.approve(STAKING_CONTRACT_ADDRESS, amount);
        await web3Provider.waitForTransaction(hash);
        let spendingLimit = await contract.allowance(walletAddress, STAKING_CONTRACT_ADDRESS);
        return ethers.utils.formatUnits(spendingLimit, 18);
        // let b = await web3Provider.getTransaction(hash);
    } else {
        return Number(filteredSpendingLimit);
    }
};

const stakeTokens = (data) => async (dispatch, getState) => {
    let { web3Provider, STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS } = getState().auth;
    const signer = await web3Provider.getSigner();
    const contract = new ethers.Contract(STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, signer);
    let { hash } = await contract.stakeTokens(data.value.stakeAmount, data.value.maxStakeAmount, data.poolId, data.signature);
    return await web3Provider.waitForTransaction(hash);
}

const unstakeMultiple = (ids) => async (dispatch, getState) => {
    let { web3Provider, STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS } = getState().auth;
    const signer = await web3Provider.getSigner();
    const contract = new ethers.Contract(STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, signer);
    let { hash } = await contract.unstakeMultiple(ids);
    return await web3Provider.waitForTransaction(hash);
}

const unstake = (stakeId) => async (dispatch, getState) => {
    let { web3Provider, STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS } = getState().auth;
    const signer = await web3Provider.getSigner();
    const contract = new ethers.Contract(STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT_ABI, signer);
    let { hash } = await contract.unstake(stakeId);
    return await web3Provider.waitForTransaction(hash);
}

const stakingActions = {
    numberOfStakes,
    getMultipleStakeInfo,
    stakeTokens,
    unstake,
    unstakeMultiple,
    balanceOfERC20,
    allowance,
    userStakedAmount,
    pools,
    getMultiplePoolDetails
};

export default stakingActions;