import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Home from 'pages/Home';
import NavBar from 'components/NavBar';
import Login from 'pages/Login';

export default function AppRouter() {

    const dispatch = useDispatch();


    const { web3Provider, walletAddress, connectedChainId } = useSelector(state => state.auth);

    useEffect(() => {
        web3Provider?.on('accountsChanged', (accounts) => {
            if (accounts.length === 0) {
                dispatch({ type: 'LOAD_WALLET_ADDRESS', payload: null })
                localStorage.clear();
                window.location.href = '/login';
            }
            if (walletAddress !== accounts[0]) {
                dispatch({ type: 'LOAD_WALLET_ADDRESS', payload: accounts[0] })
            }
        });

        web3Provider?.on('chainChanged', (_chainId) => {
            if (connectedChainId !== _chainId) {
                dispatch({ type: 'LOAD_CHAIN_CHANGED', payload: _chainId })
            }
        });
    });

    if (navigator.appVersion.indexOf('Mac') > -1) {
        document.body.className += ' MacBody';
    } else if (navigator.appVersion.indexOf('Windows') > -1) {
        document.body.className += ' WindowsBody';
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<></>} />
                <Route path='/' element={<NavBar />}>
                    <Route index element={<Home />} />
                    <Route path='home' element={<Home />} />
                    <Route path='login' element={<Login />} />
                </Route>
            </Routes>
        </BrowserRouter >
    );
}