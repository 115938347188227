import { STAKING_CONTRACT_ABI } from './STAKING_CONTRACT_ABI';
import { ERC20_ABI } from './ERC20_ABI';

const configuration = {
    ERC20_ABI: ERC20_ABI,
    ERC20_ADDRESS: process.env.REACT_APP_ERC20_ADDRESS,
    STAKING_CONTRACT_ABI: STAKING_CONTRACT_ABI,
    STAKING_CONTRACT_ADDRESS: process.env.REACT_APP_STAKING_CONTRACT_ADDRESS,
    POLYGON_INFURA_URL: process.env.REACT_APP_POLYGON_INFURA_URL,
    chains: [
        {
            chainId: process.env.REACT_APP_ENV === 'PROD' ? 137 : 80001,
            name: process.env.REACT_APP_ENV === 'PROD' ? 'Polygon Mainnet' : 'Mumbai',
            currency: 'MATIC',
            explorerUrl: process.env.REACT_APP_ENV === 'PROD' ? 'https://www.polygonscan.com/' : 'https://mumbai.polygonscan.com/',
            rpcUrl: process.env.REACT_APP_ENV === 'PROD' ? 'https://polygon-rpc.com' : 'https://rpc-mumbai.maticvigil.com'
        }
    ],
    NETWORKS: {
        'POLYGON': {
            chainId: process.env.REACT_APP_ENV === 'PROD' ? '0x89' : '0x13881',
            chainName: process.env.REACT_APP_ENV === 'PROD' ? 'Matic(Polygon) Mainnet' : 'Mumbai',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: [process.env.REACT_APP_ENV === 'PROD' ? 'https://polygon-rpc.com' : 'https://rpc-mumbai.maticvigil.com'],
            blockExplorerUrls: [process.env.REACT_APP_ENV === 'PROD' ? 'https://www.polygonscan.com/' : 'https://mumbai.polygonscan.com/']
        }
    },
    API_PREFIX: process.env.REACT_APP_API_PREFIX
};
export default configuration;
