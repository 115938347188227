export const switchNetwork = () => async (dispatch, getState) => {
    let { web3Provider, NETWORKS } = getState().auth;
    try {
        // return await web3Provider.send({ method: 'wallet_addEthereumChain', params: [NETWORKS['POLYGON']] });
        return await web3Provider.send('wallet_switchEthereumChain', [{ chainId: NETWORKS['POLYGON'].chainId }]);
    } catch (error) {
        console.error(error);
    }
};

const authActions = {
    switchNetwork
};

export default authActions;